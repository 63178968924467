import Link from 'next/link';
import React, {
  PropsWithChildren,
} from 'react';

import Logo from '../../icons/logo';
import { Container } from '../../ui';
import { HeaderRoot } from './root';

export const Header: React.FC<PropsWithChildren> = () => {
  return (
    <HeaderRoot>
      <Container>
        <div className="flex flex-row justify-between px-3 py-3 md:px-6 h-[64px]">
          <div className="flex flex-row items-center">
            <Link href="/">
              <div className="flex items-center justify-start space-x-2">
                <Logo className="w-[110px] sm:w-[130px] text-white" />
              </div>
            </Link>
          </div>
        </div>
      </Container>
    </HeaderRoot>
  );
};
