import type { NextSeoProps } from 'next-seo';
import { OpenGraphMedia } from 'next-seo/lib/types';

const siteName = 'Attack on Titan: Legacy';

const defaultTitle = 'Attack on Titan: Legacy | Official Digital Collectibles';

const titleTemplate = '%s | Attack on Titan: Legacy';

const defaultDescription =
  'The official licensed Attack on Titan NFT has arrived! Own and trade the most epic anime Moments of your beloved characters now.';

const defaultOpenGraphImage = {
  url: 'https://aotlegacy.com/assets/OGP_1200x630.jpg',
  width: 1200,
  height: 630,
};

type CustomSeoParameter = {
  url: string;
  title?: string;
  description?: string;
  image?: OpenGraphMedia;
  noindex?: boolean;
};

export const createSeoConfig = ({
  url,
  title,
  description,
  image,
  noindex = false,
}: CustomSeoParameter): NextSeoProps => {
  const t = title ? titleTemplate.replace('%s', title) : defaultTitle;
  return {
    title: t,
    description: description ?? defaultDescription,
    noindex,
    openGraph: {
      site_name: siteName,
      url,
      type: 'website',
      title: t,
      images: [image ?? defaultOpenGraphImage],
      description: description ?? defaultDescription,
    },
    twitter: {
      site: '@AoTLegacy',
      cardType: 'summary_large_image',
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: 'titan,Attack on titan,進撃の巨人,巨人,諫山創,荒木哲郎,梶裕貴,神谷浩史,石川由依,井上麻里奈,ape,nft',
      }
    ],
    additionalLinkTags: [
      {
        rel: 'icon',
        href: '/favicons/favicon.ico',
      },
      {
        rel: 'apple-touch-icon',
        href: '/favicons/apple-touch-icon.png',
        sizes: '76x76',
      },
      {
        rel: 'manifest',
        href: '/favicons/manifest.json',
      },
    ],
  };
};
