import { XIcon } from '@heroicons/react/solid';
import Portal from '@reach/portal';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import cn from 'classnames';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import ms from './modal.module.css';

export type ModalProps = PropsWithChildren<{
  primary?: boolean;
  open?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onEnter?: () => void | null;
  fullScreen?: boolean;
}>;

export const Modal: React.FC<ModalProps> = ({
  children,
  open,
  loading,
  onClose,
  primary = true,
  fullScreen = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        return onClose && onClose();
      }
    },
    [onClose],
  );

  const onClick = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (open) {
      disableBodyScroll(ref.current);
      window.addEventListener('keydown', onKeyDown);
    } else {
      enableBodyScroll(ref.current);
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      clearAllBodyScrollLocks();
    };
  }, [open, onKeyDown]);

  return (
    <Portal>
      {open && (
        <div className={cn(ms.root)}>
          <div id="modal-bg" className="absolute w-full h-full" />

          <div
            id="modal"
            className={cn(
              ms.modal,
              primary ? 'bg-primary text-white' : ' bg-white text-black',
              fullScreen ? 'w-full h-full' : ms.defaultSize,
            )}
            role="dialog"
            ref={ref}
          >
            <div className="w-full h-full">{children}</div>

            <button
              disabled={loading}
              onClick={onClick}
              aria-label="Close panel"
              className="absolute top-0 right-0 p-4 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none"
            >
              <XIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      )}
    </Portal>
  );
};
