import NextHead from 'next/head';
import { NextSeo } from 'next-seo';
import React from 'react';

import { createSeoConfig } from '../../../libs/seo';

type HeadProps = {
  title?: string;
  description?: string;
  disableSeo?: boolean;
};

const Head: React.FC<HeadProps> = ({
  title,
  description,
  disableSeo = false,
}) => {
  const props = createSeoConfig({
    url: typeof window !== 'undefined' ? window.location.href : '',
    title,
    description,
    noindex: disableSeo,
  });

  return (
    <>
      <NextSeo {...props} />
      <NextHead>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </NextHead>
    </>
  );
};

export default Head;
