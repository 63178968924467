import cn from 'classnames';
import React, {
  CSSProperties,
  JSXElementConstructor,
  PropsWithChildren,
  ReactNode,
} from 'react';

import s from './text.module.css';

export type TextVariant =
  | 'body'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'ol'
  | 'ul'
  | 'label'
  | 'link';

const componentsMap: {
  [P in TextVariant]: React.ComponentType<PropsWithChildren<any>> | string;
} = {
  body: 'div',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  ol: 'ol',
  ul: 'ul',
  label: 'div',
  link: 'span',
};

export interface TextProps {
  variant?: TextVariant;
  className?: string;
  style?: CSSProperties;
  html?: string;
  htmlFor?: string;
  vertical?: boolean;
  useStrokeText?: boolean;
  children?: ReactNode;
}

export const Text: React.FC<PropsWithChildren<TextProps>> = ({
  style,
  className = '',
  variant = 'body',
  children,
  html,
  htmlFor,
  vertical,
  useStrokeText,
}) => {
  const Component:
    | JSXElementConstructor<any>
    | React.ReactElement<any>
    | React.ComponentType<PropsWithChildren<any>>
    | string = componentsMap![variant!];

  const htmlContentProps = html
    ? {
        dangerouslySetInnerHTML: { __html: html },
      }
    : {};

  return (
    <Component
      className={cn(
        s[variant],
        { [s.stroke]: useStrokeText, [s.vertical]: vertical },
        className,
      )}
      style={style}
      htmlFor={htmlFor}
      {...htmlContentProps}
    >
      {children}
    </Component>
  );
};
