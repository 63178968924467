import Link from 'next/link';
import { FC, PropsWithChildren } from 'react';

type CustomLinkProps = {
  href: string;
  target?: string;
  className?: string;
};

export const CustomLink: FC<PropsWithChildren<CustomLinkProps>> = ({
  href,
  children,
  className,
  target = '_blank',
}) =>
  href.startsWith('/') || href === '' ? (
    <Link href={href} className={className}>
      {children}
    </Link>
  ) : (
    <a
      href={href}
      target={target}
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  );
