import cn from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { Container } from '../../ui';
import Footer from '../footer';
import Head from '../head';
import { Header } from '../header';

export type PageLayoutProps = {
  title?: string;
};

export const PageLayout: FC<PropsWithChildren<PageLayoutProps>> = ({
  title,
  children,
}) => {
  return (
      <div>
        <div
            className={cn('h-full mx-auto transition-colors duration-150')}
        >
          <Head title={title} />
          <Header />
          <main
              role="main"
              className="relative flex pt-[64px] min-h-[calc(100vh-185px)] md:min-h-[calc(100vh-105px)]"
          >
            <Container className="flex flex-col justify-center">
              {children}
            </Container>
          </main>
          <Footer />
        </div>
      </div>

  );
};
