import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import s from './container.module.css';

export interface ContainerProps {
  className?: string;
  clean?: boolean;
}

export const Container: React.FC<PropsWithChildren<ContainerProps>> = ({
  children,
  className,
  clean,
}) => {
  const rootClassName = cn(className, s.root, {
    'mx-auto max-w-8xl': !clean,
  });

  return <div className={rootClassName}>{children}</div>;
};
