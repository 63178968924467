import React from 'react';

import { Container } from '../../ui';
import { CustomLink } from '../../ui/link';

const main = [
  { name: 'Term of Use', href: '/terms' },
  { name: 'Privacy Policy', href: '/privacy' },
];

const Footer: React.FC = () => {
  return (
    <footer className="relative w-full bg-gradient-to-t from-black">
      <Container>
        <div className="px-3 py-6 flex md:flex-row flex-col mx-auto text-white select-none md:px-6 max-w-7xl">
          <div className="md:flex md:items-center md:justify-between">
            <nav
              className="flex flex-col flex-wrap text-center md:text-left pb-8 md:pb-0 justify-center md:order-2"
              aria-label="Footer"
            >
              {main.map((item) => (
                <div key={item.name} className="py-1 pr-4">
                  <div className="text-sm underline hover:text-gray-300">
                    <CustomLink href={item.href}>
                      {item.name}
                    </CustomLink>
                  </div>
                </div>
              ))}
            </nav>
          </div>
          <div className="flex-1" />
          <div className="flex flex-col text-xs text-center md:text-right md:mt-0 md:order-1">
            <p>
              Officially licensed product of the Attack on Titan Production
              Committee.
            </p>
            <p>© 2021 Anique USA Inc. All Rights Reserved.</p>
            <p>
              © Hajime Isayama/Kodansha Ltd.
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
