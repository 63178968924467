import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useReducer,
} from 'react';

type ModalViews = 'AUTH_VIEW' | 'IDENTITY_VIEW';

type ModalViewProps = Record<string, unknown>;

type ToastText = string;

export interface State {
  displayModal: boolean;
  displayOnboard: boolean;
  displayToast: boolean;
  modalView: ModalViews;
  modalViewProps?: ModalViewProps;
  toastText: ToastText;
  loading: boolean;
}

const initialState = {
  displayModal: false,
  modalView: 'AUTH_VIEW' as const,
  displayOnboard: false,
  displayToast: false,
  toastText: '',
  modalViewProps: {},
  loading: false,
};

type Action =
  | {
      type: 'OPEN_TOAST';
      text: ToastText;
    }
  | {
      type: 'CLOSE_TOAST';
    }
  | {
      type: 'OPEN_MODAL';
      view: ModalViews;
      viewProps?: ModalViewProps;
    }
  | {
      type: 'CLOSE_MODAL';
    }
  | {
      type: 'OPEN_ONBOARD';
    }
  | {
      type: 'CLOSE_ONBOARD';
    }
  | {
      type: 'SHOW_LOADING';
    }
  | {
      type: 'HIDE_LOADING';
    };

export const UIContext = React.createContext<State | any>(initialState);

UIContext.displayName = 'UIContext';

function uiReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'OPEN_MODAL': {
      return {
        ...state,
        modalView: action.view,
        modalViewProps: action.viewProps,
        displayModal: true,
      };
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      };
    }
    case 'OPEN_ONBOARD': {
      return {
        ...state,
        displayOnboard: true,
      };
    }
    case 'CLOSE_ONBOARD': {
      return {
        ...state,
        displayOnboard: false,
      };
    }
    case 'OPEN_TOAST': {
      return {
        ...state,
        toastText: action.text,
        displayToast: true,
      };
    }
    case 'CLOSE_TOAST': {
      return {
        ...state,
        displayToast: false,
      };
    }
    case 'SHOW_LOADING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'HIDE_LOADING': {
      return {
        ...state,
        loading: false,
      };
    }
  }
}

type UIContextProps = State & {
  openModal: (view: ModalViews, props?: ModalViewProps) => void;
  closeModal: () => void;
  openOnboard: () => void;
  closeOnboard: () => void;
  openToast: (text: ToastText) => void;
  closeToast: () => void;
  showLoading: () => void;
  hideLoading: () => void;
};

export const UIProvider: React.FC<PropsWithChildren<unknown>> = (props) => {
  const [state, dispatch] = useReducer(uiReducer, initialState);
  const { loading, displayModal, displayOnboard } = state;

  const openModal = useCallback(
    (view: ModalViews, props?: ModalViewProps) => {
      if (!displayModal) {
        dispatch({ type: 'OPEN_MODAL', view, viewProps: props });
      }
    },
    [displayModal],
  );

  const closeModal = useCallback(() => {
    if (displayModal) {
      dispatch({ type: 'CLOSE_MODAL' });
    }
  }, [displayModal]);

  const openOnboard = useCallback(() => {
    if (!displayOnboard) {
      dispatch({ type: 'OPEN_ONBOARD' });
    }
  }, [displayOnboard]);

  const closeOnboard = useCallback(() => {
    if (displayOnboard) {
      dispatch({ type: 'CLOSE_ONBOARD' });
    }
  }, [displayOnboard]);

  const openToast = (text: ToastText) => dispatch({ type: 'OPEN_TOAST', text });
  const closeToast = () => dispatch({ type: 'CLOSE_TOAST' });

  const showLoading = useCallback(() => {
    if (!loading) {
      dispatch({ type: 'SHOW_LOADING' });
    }
  }, [loading]);

  const hideLoading = useCallback(() => {
    if (loading) {
      dispatch({ type: 'HIDE_LOADING' });
    }
  }, [loading]);

  const value = useMemo(
    () => ({
      ...state,
      openModal,
      closeModal,
      openOnboard,
      closeOnboard,
      openToast,
      closeToast,
      showLoading,
      hideLoading,
    }),
    [
      state,
      showLoading,
      hideLoading,
      openModal,
      closeModal,
      openOnboard,
      closeOnboard,
    ],
  );

  return <UIContext.Provider value={value} {...props} />;
};

export const useUI = (): UIContextProps => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};

export const ManagedUIContext: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => <UIProvider>{children}</UIProvider>;
