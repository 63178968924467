import cn from 'classnames';
import React, {
  ChangeEvent,
  PropsWithChildren,
  SelectHTMLAttributes,
  useCallback,
} from 'react';

import s from './selector.module.css';

export type OptionData = {
  label: string;
  value: string;
};

export type SelectorProps = SelectHTMLAttributes<HTMLSelectElement> & {
  clearValue?: boolean;
  primary?: boolean;
  options?: OptionData[];
  onChangeValue?: (value: string) => void;
};

export const Selector: React.FC<PropsWithChildren<SelectorProps>> =
  React.forwardRef<HTMLSelectElement, SelectorProps>(
    (
      {
        className,
        options,
        primary = true,
        clearValue = true,
        defaultValue,
        onChangeValue,
        ...rest
      },
      ref,
    ) => {
      const handleChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
          if (onChangeValue) {
            onChangeValue(e.target.value);
          }

          return null;
        },
        [onChangeValue],
      );

      const initValue = clearValue && !defaultValue ? 'none' : defaultValue;
      return (
        <select
          ref={ref}
          className={cn(s.root, s.item, { [s.primary]: primary }, className)}
          onChange={handleChange}
          defaultValue={initValue}
          {...rest}
        >
          {clearValue && <option key="none" value="none" label=" -- " />}
          {options?.map((o, i) => {
            return (
              <option
                key={i}
                value={o.value}
                label={o.label}
                className={cn(s.option, {
                  [s.primary]: primary,
                })}
              >
                {o.label}
              </option>
            );
          })}
        </select>
      );
    },
  );

Selector.displayName = 'Selector';
