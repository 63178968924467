import { ExclamationCircleIcon } from '@heroicons/react/solid';
import cn from 'classnames';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  PropsWithChildren,
  Ref,
  useCallback,
} from 'react';

import { Hint } from '../hint';
import { Text } from '../text';
import s from './input.module.css';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  ref?: Ref<HTMLInputElement>;
  primary?: boolean;
  errorMessage?: string;
  onChangeValue?: (value: string) => void;
};

export const Input: React.FC<PropsWithChildren<InputProps>> = React.forwardRef<
  HTMLInputElement,
  InputProps
>(
  (
    {
      className,
      primary = true,
      errorMessage,
      onChangeValue,
      autoComplete = 'off',
      ...rest
    },
    ref,
  ) => {
    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (onChangeValue) {
          onChangeValue(e.target.value);
        }

        return null;
      },
      [onChangeValue],
    );

    return (
      <div className="flex items-center space-x-1">
        <input
          ref={ref}
          className={cn(
            s.root,
            { [s.primary]: primary, [s.error]: !!errorMessage },
            className,
          )}
          onChange={handleOnChange}
          autoComplete={autoComplete}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          {...rest}
        />
        {errorMessage && (
          <Hint
            theme={{
              backgroundColor: 'bg-red-500',
              borderColor: 'border-red-500',
            }}
            target={
              <ExclamationCircleIcon
                className="w-5 h-5 text-red-500"
                aria-hidden="true"
              />
            }
          >
            <Text variant="h6" className="text-white">
              {errorMessage}
            </Text>
          </Hint>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
