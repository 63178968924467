import cn from 'classnames';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  PropsWithChildren,
  useCallback,
} from 'react';

import s from './check.module.css';

export type CheckBoxProps = InputHTMLAttributes<HTMLInputElement> & {
  onChangeValue?: (value: boolean) => void;
  label?: string;
  primary?: boolean;
  rounded?: boolean;
  ref?: React.ForwardedRef<HTMLInputElement>;
  disabled?: boolean;
};

export const CheckBox: React.FC<PropsWithChildren<CheckBoxProps>> =
  React.forwardRef<HTMLInputElement, CheckBoxProps>(function InputBase(
    {
      className,
      label,
      type = 'checkbox',
      primary = true,
      rounded = false,
      disabled = false,
      onChangeValue,
      ...rest
    },
    ref,
  ) {
    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (onChangeValue) {
          onChangeValue(e.target.checked);
        }

        return null;
      },
      [onChangeValue],
    );

    return (
      <div className="flex items-center">
        <input
          ref={ref}
          className={cn(
            s.root,
            { [s.primary]: primary },
            { 'pointer-events-none !border-none': disabled },
            rounded ? 'rounded-full' : '',
            className,
          )}
          type={type}
          {...rest}
          onChange={handleOnChange}
          disabled={disabled}
          id={label}
        />
        {label && (
          <label className={cn('ml-4 text-gray-500', s.label)} htmlFor={label}>
            {label}
          </label>
        )}
      </div>
    );
  });
