import cn from 'classnames';
import React, { CSSProperties, PropsWithChildren } from 'react';

import mc from './skeleton.module.css';

const px = (value: number | string): string => {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  return value;
};

const mergeStyle = ({
  width,
  height,
  boxHeight,
  style,
}: SkeletonStyle): CSSProperties => {
  width = width || 24;
  height = height || 24;
  boxHeight = boxHeight || height;

  return {
    minWidth: px(width),
    minHeight: px(height),
    marginBottom: `calc(${px(boxHeight)} - ${px(height)})`,
    ...style,
  };
};

type SkeletonStyle = {
  width?: string | number;
  height?: string | number;
  boxHeight?: string | number;
  style?: CSSProperties;
};

export type SkeletonProps = SkeletonStyle & {
  show?: boolean;
  block?: boolean;
  className?: string;
};

const Skeleton: React.FC<PropsWithChildren<SkeletonProps>> = (props) => {
  const { children, width, height, className, show } = props;

  const shouldAutoSize = !!children && !(width || height);
  const loaded = !shouldAutoSize && !!children;
  return (
    <span
      className={cn(mc.skeleton, className, {
        [mc.show]: show,
        [mc.wrapper]: shouldAutoSize,
        [mc.loaded]: loaded,
      })}
      style={shouldAutoSize ? {} : mergeStyle(props)}
    >
      {children}
    </span>
  );
};

export default Skeleton;
