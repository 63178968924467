import 'react-loading-skeleton/dist/skeleton.css';

import cn from 'classnames';
import NextLegacyImage from 'next/legacy/image';
import { StaticImageData } from 'next/legacy/image';
import { FC, PropsWithChildren, ReactNode } from 'react';

export type ImageBoxProps = {
  className?: string;
  src?: string | StaticImageData;
  alt?: string;
  children?: ReactNode;
  onLoadStart?: () => void;
  onLoadEnd?: (result: { naturalWidth: number; naturalHeight: number }) => void;
};

export const ImageBox: FC<PropsWithChildren<ImageBoxProps>> = ({
  className = 'min-w-[60px]',
  src,
  alt = '',
  children,
  onLoadStart,
  onLoadEnd,
}) => {
  return (
    <div className={className}>
      <div
        className={cn('relative p-0 w-full h-full', {
          'bg-gray-500': !src,
        })}
      >
        {src && (
          <ImageEx
            src={src}
            layout="fill"
            alt={alt}
            objectFit="cover"
            onLoadStart={onLoadStart}
            onLoadingComplete={onLoadEnd}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export const ImageEx = NextLegacyImage;
