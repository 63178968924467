import React from 'react';

type LoadingProps = {
  error?: Error | null;
  isLoading?: boolean;
  pastDelay?: boolean;
  retry?: () => void;
  timeout?: boolean;
};

const Loading: React.FC<LoadingProps> = () => {
  return (
    <div className="flex items-center justify-center p-3 text-center w-80 h-80">
      ...
    </div>
  );
};

export default Loading;
