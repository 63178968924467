import { FC, PropsWithChildren } from 'react';

import styles from './processing.module.css';

export type ProcessingProps = {
  size?: number;
  color?: string;
  lineWidth?: number;
};

export const Processing: FC<PropsWithChildren<ProcessingProps>> = ({
  size = 34,
  color = 'rgb(0, 0, 0)',
  lineWidth = 20,
}) => {
  return (
    <span
      className={styles.root}
      style={{
        width: `${size + lineWidth * 2}px`,
        height: `${size + lineWidth * 2}px`,
        borderColor: `${color} ${color} transparent`,
        borderWidth: `${lineWidth}px`,
      }}
    />
  );
};
