import cn from 'classnames';
import React, {
  ButtonHTMLAttributes,
  forwardRef,
  JSXElementConstructor,
  PropsWithChildren,
} from 'react';

import { Processing } from '../../checkout/processing';
import { Text } from '../text';
import s from './button.module.css';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  active?: boolean;
  Component?: string | JSXElementConstructor<any>;
  width?: string | number;
  loading?: boolean;
  disabled?: boolean;
}

export const Button: React.FC<PropsWithChildren<ButtonProps>> = forwardRef<
  HTMLButtonElement,
  ButtonProps
>(function Button(props, buttonRef) {
  const {
    className,
    children,
    active,
    type,
    width,
    loading = false,
    disabled = false,
    style = {},
    Component = 'button',
    ...rest
  } = props;

  const rootClassName = cn(
    s.root,
    type && s[type],
    {
      [s.loading]: loading,
      [s.disabled]: disabled,
    },
    className,
  );

  const isLabel = typeof children === 'string';
  return (
    <Component
      aria-pressed={active}
      ref={buttonRef}
      className={rootClassName}
      disabled={disabled || loading}
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      {loading ? (
        <Processing color={'rgb(255, 255, 255)'} size={12} lineWidth={4} />
      ) : isLabel ? (
        <Text variant="h5" className="!font-bold uppercase">
          {children}
        </Text>
      ) : (
        children
      )}
    </Component>
  );
});
