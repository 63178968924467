import { Padding, Placement } from '@popperjs/core';
import Tippy from '@tippyjs/react/headless';
import cn from 'classnames';
import { FC, PropsWithChildren, ReactNode, useState } from 'react';

type Theme = {
  borderColor: string;
  backgroundColor: string;
};

export type HintProps = {
  target: ReactNode;
  placement?: Placement;
  theme?: Theme;
  padding?: Padding;
  trigger?: 'mouseenter click' | 'click' | 'focus' | 'mouseenter' | 'manual';
  disabled?: boolean;
  arrow?: boolean;
  onClickOutside?: () => void;
  children?: ReactNode;
};

export const Hint: FC<PropsWithChildren<HintProps>> = ({
  placement = 'auto',
  target,
  theme = {
    borderColor: 'border-black',
    backgroundColor: 'bg-white',
  },
  trigger = 'mouseenter',
  padding = 10,
  disabled = false,
  arrow = true,
  onClickOutside,
  children,
}) => {
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  return (
    <Tippy
      interactive={false}
      trigger={trigger}
      disabled={disabled}
      zIndex={30}
      onClickOutside={onClickOutside}
      render={(attrs) => (
        <div {...attrs}>
          <div
            className={cn(
              'relative inline-block px-3 py-2 rounded-sm border-2',
              theme.backgroundColor,
              theme.borderColor,
            )}
          >
            {children}
          </div>
          {arrow && (
            <div
              ref={setArrowElement}
              data-popper-arrow=""
              className={`w-2 h-2 relative`}
            >
              <div
                className={`absolute w-2 h-2 rotate-45 border-b-2 border-r-2 bottom-[4px] ${theme.backgroundColor} ${theme.borderColor}`}
              />
            </div>
          )}
        </div>
      )}
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: arrowElement,
              padding,
            },
          },
          { name: 'flip', enabled: false }, //上下のポジションの移動を無くす
        ],
        placement,
      }}
    >
      <button>{target}</button>
    </Tippy>
  );
};
